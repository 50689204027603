import React from 'react';
import { Layout, Section, Link } from 'components';
import * as styles from './thank-you.module.scss';

const ThankYou = (props) => (
  <Layout>
    <Section className={styles.section}>
      <h3>Thanks for your submission</h3>
      <h5>A member of our team will be in contact with you shortly</h5>
      <Link to="/" className={`button ${styles.button}`} withArrow>
        Back to home
      </Link>
    </Section>
  </Layout>
);
export default ThankYou;
